import React, { Component } from 'react'

class Logo extends Component {
  render() {
    let { color } = this.props

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24">
        <g fill="none" fillRule="nonzero" stroke={color} strokeWidth="1.3">
          <path d="M11 1.016L20.933 12 11 22.984 1.067 12z"/>
          <path strokeLinecap="square" d="M1.313 12.001h19.514M6.051 6.676l4.175 15.386"/>
        </g>
      </svg>
    )
  }
}

export default Logo