import React, { Component } from 'react'

import mute from '../assets/images/mute-icon.svg'
import unmute from '../assets/images/unmute-icon.svg'
import play from '../assets/images/play-icon.svg'
import pause from '../assets/images/pause-icon.svg'

class Video extends Component {

  state = {
    top: this.props.position.y,
    left: this.props.position.x,
    width: this.props.size.w,
    height: this.props.size.h,
    transition: 'none',
    position: 'fixed',
    muted: true,
    paused: false,
    image: this.props.image,
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.refs.video.load()
      let { top, left, width, height, transition, position } = this.state
      top = 0
      left = 0
      width = '100%'
      height = '85vh'
      transition = '0.5s ease-in-out'
      position = 'absolute'
      this.setState({ top, left, width, height, transition, position })
    }, 10)

    this.refs.video.defaultMuted = true;

    this.refs.video.addEventListener('ended', () => {
      if (this.props.loop === false) {
        this.refs.video.autoplay = false
        this.refs.video.load()
        this.setState({ paused: true })
      }
    })
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.image !== prevState.image){
      return { 
        image: nextProps.image,
        top: nextProps.position.y,
        left: nextProps.position.x,
        width: nextProps.size.w,
        height: nextProps.size.h,
        transition: 'none',
        position: 'fixed',
      }
    }
    else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.image !== this.props.image) {
      this.refs.video.load()
      this.refs.video.play()
      this.timeout = setTimeout(() => {
        let { top, left, width, height, transition, position } = this.state
        top = 0
        left = 0
        width = '100%'
        height = '85vh'
        transition = '0.5s ease-in-out'
        position = 'absolute'
        this.setState({ top, left, width, height, transition, position })
      }, 10)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  _pause() {
    let { video } = this.refs
    if (video.paused) {
      video.play()
      this.setState({ paused: false })
    } else {
      video.pause()
      this.setState({ paused: true })
    }
  }

  _mute() {
    this.setState({ muted: !this.state.muted })
  }

  render() {
    let { top, left, width, height, transition, position, muted, paused, image } = this.state
    let { video } = this.props
    // Loop defaults to true
    let loop = this.props.loop === false ? this.props.loop : true
    return (
      <div className='video-transition' style={{
        top, left, width, height, transition, position,
      }}>
        <video ref={'video'} src={video} poster={image} loop={loop} autoPlay={'autoplay'} muted={muted} playsInline={true} />
        { video && 
          <div className='video-transition__controls'>
            <div onClick={this._pause.bind(this)}>
              <div>
                <img src={ paused ? play : pause } style={{marginLeft: paused ? 3 : 0}} alt='play/pause video' />
              </div>
            </div>
            <div onClick={this._mute.bind(this)}>
              <div>
                <img src={ muted ? mute : unmute } style={{marginRight: muted ? 0 : 5}} alt='mute video' />
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default Video
