import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import ContextConsumer, { ContextProviderComponent } from '../utils/context'

import Header from '../components/header'
import Footer from '../components/footer'
import Video from '../components/video'
import favicon from '../assets/images/favicon.png';
import ogImage from '../assets/images/og-image.jpg'
import '../assets/scss/main.scss'

const Layout = (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        allWpService(sort: {menuOrder: ASC}) {
          edges {
            node {
              title
              slug
            }
          }
        }
        wp {
          siteSettings {
            acf {
              footerContent
              newsletter
            }
          }
        }
      }
    `}
    render={(data) => {

      let { location, children } = props

      let context = (props) => {
        let video = props.data.image ? <Video {...props.data} /> : null
        let light = location.pathname === '/' || video
        if (children.props.pageContext.type !== 'single-work' && video !== null) {
          props.set({ image: null })
        }
        return (<>
          <Header siteTitle={data.site.siteMetadata.title} services={data.allWpService} light={light} />
          { video }
        </>)
      }

      return (
        <ContextProviderComponent>
          <Helmet
            defaultTitle={`Ramus`} titleTemplate={`%s`}
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'Sample' },
              { name: 'keywords', content: 'sample, something' },
              { name: 'google-site-verification', content: 'ITpD-WlKV67m_uJz4bQo28aQhjg0EaU6zAutrwislg4' }
            ]}
          >
            <html lang="en" />
            <link rel="icon" href={favicon} sizes="32x32" type="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image" content={ogImage} />
            <meta property="og:image:url" content={ogImage} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Ramus - Our work is luminous art." />
            <meta property="og:description" content="Design studio led by Bruce Ramus. Creating luminous art and integrated public precincts using light, sculpture, digital media and technology." />
            <meta property="og:site_name" content="Ramus" />
            <meta property="og:url" content={typeof window !== 'undefined' && window.location} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:description" content="Design studio led by Bruce Ramus. Creating luminous art and integrated public precincts using light, sculpture, digital media and technology." />
            <meta name="twitter:title" content="Ramus - Our work is luminous art." />
          </Helmet>
          <ContextConsumer>{ context }</ContextConsumer>
          <main className="site-wrapper">
            { children }
          </main>
          { location.pathname !== '/' && <Footer footerSettings={data.wp.siteSettings.acf} /> }
        </ContextProviderComponent>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
